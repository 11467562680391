import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import "twin.macro";

import ExpertsSvg from "../../static/images/experts.svg";
import { Layout } from "../components/Layout/Layout";
import TheBigTrio from "../components/TheBigTrio";
import { getTranslationList } from "../utils/common";

const Experts: React.FC = () => {
  const { t } = useTranslation();

  const list = getTranslationList(t, "experts.list");

  return (
    <Layout>
      <Helmet title={t("experts.header")} />
      <TheBigTrio header="experts.mainExpertsHeader" subheader="experts.subheader" icon={<ExpertsSvg tw="h-48 mr-12" />} list={list} />
    </Layout>
  );
};

export default Experts;
